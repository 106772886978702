<template>
  <div class="bannerOrlist">
    <div class="bannerOrlist-bar">
      <h3 class="title">{{title}}</h3>
      <div class="count">
        <span>数量</span>
        <span>{{count}}</span>
      </div>
    </div>
    <div class="bannerOrlist-content" v-if="status == 'banner'">
      <ul>
        <li class="bannerOrlist-list bar">
          <dd>序号</dd>
          <dd>图片</dd>
          <dd>主题名称</dd>
          <dd>状态</dd>
          <dd>操作</dd>
        </li>
        <li class="bannerOrlist-list" v-for="(item, index) in items" :key="index">
          <dd>{{index | changeIndex}}</dd>
          <dd>
            <img class="image" :src="item.pic" alt />
          </dd>
          <dd>{{item.theme | changeNot}}</dd>
          <dd>{{item.state | changeState}}</dd>
          <dd class="icon">
            <div>
              <img src="@/assets/imgs/updateRobot.png" alt />
              <span @click="updateBanner(item)">修改</span>
            </div>
            <span @click="deleteBanner(item.id)">删除</span>
          </dd>
        </li>
      </ul>
      <div class="add-item">
        <button @click="addBanner">添加</button>
      </div>
    </div>

    <div class="bannerOrlist-content" v-if="status == 'list'">
      <ul>
        <li class="bannerOrlist-list bar">
          <dd v-for="(item, index) in itemBar" :key="index">{{item}}</dd>
        </li>
        <li v-if="name == 'group'">
          <div class="bannerOrlist-list" v-for="(item, index) in listPage" :key="index">
            <dd>{{index | changeIndex}}</dd>
            <dd class="cover-one">
              <img :src="item.faceUrl" alt />
            </dd>
            <dd>{{item | changeItem('name')}}</dd>
            <dd>{{item | changeItem('sex')}}</dd>
            <dd>{{item | changeItem('country')}}</dd>
            <dd>{{item.group_name}}</dd>
            <dd>{{item.type | changeGroup}}</dd>
            <dd>{{item.language}}</dd>
            <dd>{{item.people_num}}</dd>
            <dd class="last-dd">
              <span @click="nextGroupList(item.id)">查看详情</span>
              <span @click="deleteGroup(item.id)">删除</span>
            </dd>
          </div>
        </li>
        <li v-if="name == 'user'">
          <div class="bannerOrlist-list" v-for="(item, index) in items" :key="index">
            <dd>{{index | changeIndex}}</dd>
            <dd class="cover-two">
              <img :src="item.avatar" alt />
            </dd>
            <dd>{{item.nick_name}}</dd>
            <dd>{{item.sex | changeSex}}</dd>
            <dd>{{item.country}}</dd>
            <dd>{{item.overseas_identity_name}}</dd>
            <dd>{{item.hometown}}</dd>
            <dd>{{item.preference}}</dd>
            <dd class="last-dd">
              <span @click="nextDetails(item.user_id)">查看详情</span>
              <span @click="deleteUser(item.advertising_position, item.user_id)">删除</span>
            </dd>
          </div>
        </li>
        <li v-if="name == 'teacher'">
          <div class="bannerOrlist-list" v-for="(item, index) in items" :key="index">
            <dd>{{index | changeIndex}}</dd>
            <dd class="cover-three">
              <video src></video>
            </dd>
            <dd>{{item.type}}</dd>
            <dd>{{item.nick_name}}</dd>
            <dd>{{item.sex | changeSex}}</dd>
            <dd>{{item.teach_language}}</dd>
            <dd>{{item.language}}</dd>
            <dd>{{item.score}}</dd>
            <dd class="last-dd">
              <span @click="nextPartTimeTeacherDetails(item.user_id, item)">查看详情</span>
              <span @click="deleteTeacher(item.id)">删除</span>
            </dd>
          </div>
        </li>
      </ul>
      <div class="add-item">
        <button @click="addList">添加</button>
      </div>
    </div>
  </div>
</template>


<script>
import {mapState } from "vuex";
export default {
  name: "busbannerOrlist",
  props: {
    name: String,
    title: String,
    count: Number,
    items: Array,
    status: String,
    itemBar: Array,
    buttonIndex: [String, Number],
    listItem: Object,
    page: [String, Number]
  },
  data() {
    return {
      listPage: [
        {
          map: {
            userinfo: {}
          }
        }
      ]
    };
  },
  created() {},
  computed: {
    groupItme() {
      let a = [
        {
          map: {
            userinfo: {}
          }
        }
      ];
      a = this.items;
      return a;
    }
  },
  methods: {
    addBanner() {
      this.$store.state.isBlack = true;
      this.$store.state.addBanner = true;
    },
    addList() {
      this.$store.state.isBlack = true;
      this.$store.state.addList = true
      this.$store.state.itemBannerOrList = this.listItem
    },
    deleteBanner(id) {
      this.$axios
        .get("/user/exhibitionPic/delete?id=" + id)
        .then(res => {
          this.$emit("Refresh");
        });
    },
    deleteTeacher(id) {
      let data = {
        recommend_type: "",
        id: id
      };
      this.$axios
        .post("/user/masterInfo/update", data)
        .then(res => {
          this.$emit("Refresh");
        });
    },
    deleteGroup(id) {
      let data = {
        recommend_type: "",
        id: id
      };
      this.$axios
        .post("/message/messageGroup/update", data)
        .then(res => {
          this.$emit("Refresh");
        });
    },
    deleteUser(adv, id) {
      let data = {
        advertising_position: !adv,
        user_id: id
      };
      this.$axios
        .post("/user/userInfo/update", data)
        .then(res => {
          this.$emit("Refresh");
        });
    },
    updateBanner(item) {
      this.addBanner();
      this.$store.state.itemBannerOrList = item
    },
    nextDetails(id) {
      this.$router.push({
        name: "UserDetails",
        query: {
          userID: id,
          path: this.$route.name,
          barIndex: this.buttonIndex,
          RouterName: "Ad",
          page: this.page
        }
      });
    },
    nextPartTimeTeacherDetails(id, item) {
      this.$router.push({
        name: "PartTimeTeacherDetails",
        query: {
          userID: id,
          path: this.$route.name,
          page: this.page,
          barIndex: this.buttonIndex,
          earnings: item.earnings,
          RouterName: "Ad"
        }
      });
    },
    nextGroupList(id) {
      this.$router.push({
        name: "GroupList",
        query: {
          id: id,
          path: this.$route.name,
          barIndex: this.buttonIndex,
          RouterName: "Ad",
          page: this.page
        }
      });
    }
  },
  watch: {
    items(newV, oldV) {
      this.listPage = newV;
    }
  },
  filters: {
    changeNot(e){
        return e
    },
    changeIndex(e) {
      if (e) {
        return parseInt(e) + 1;
      } else {
        return 1;
      }
    },
    changeState(e) {
      if (e) {
        if (e == 1) {
          return "使用中";
        } else {
          return "已弃用";
        }
      }
    },
    changeItem(e, type) {
      if (e.map) {
        if (e.map.userinfo) {
          if (type == "name") {
            return e.map.userinfo.nick_name;
          } else if (type == "sex") {
            if (e.map.userinfo.sex == 1) {
              return "男";
            } else {
              return "女";
            }
          } else if (type == "country") {
            return e.map.userinfo.country;
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.bannerOrlist {
  margin-top: 30px;

  .bannerOrlist-bar {
    background-color: #FFE4AF;
    display: flex;
    height: 42px;
    align-items: center;
    color: #333;

    .title {
      margin-left: 20px;
      margin-right: 50px;
      font-size: 20px;
      font-weight: bold;
      font-family: PingFang-SC-Bold;
    }

    .count {
      span {
        margin: 0 20px;
        font-size: 20px;
        font-weight: Regular;
        font-family: MicrosoftYaHei;
      }
    }
  }

  .bannerOrlist-content {
    border: 2px solid #FFE4AF;

    .bannerOrlist-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cover-one {
        width: 120px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .cover-two {
        img {
          width: 65px;
          height: 65px;
          border-radius: 50%;
        }
      }

      .cover-three {
        video {
          width: 144px;
          height: 85px;
        }
      }

      dd {
        width: 20%;
        margin: 10px 0;
        text-align: center;

        .image {
          width: 200px;
          // width auto
          height: 85px;
          height: auto;
        }

        &:nth-of-type(1) {
          width: 10%;
        }

        &:nth-of-type(2) {
          width: 15%;
        }
      }

      .last-dd {
        display: flex;
        justify-content: space-around;

        span {
          cursor: pointer;
        }

        span:nth-of-type(1) {
          color: #47CECF;
        }

        span:nth-of-type(2) {
          color: #FF0000;
        }
      }

      .icon {
        color: #FF0000;
        font-size: 14px;
        font-weight: Regular;
        font-family: MicrosoftYaHei;
        display: flex;
        justify-content: space-around;

        span {
          cursor: pointer;
        }

        div {
          span {
            color: #2CB1E8;
          }
        }

        img {
          width: 13px;
          height: 13px;
        }
      }
    }

    .bar {
      background-color: #F3F3F3;
      height: 40px;
    }
  }

  .add-item {
    width: 10%;
    text-align: center;
    height: 64px;
    line-height: 64px;
    cursor: pointer;
    z-index: 9999;

    button {
      cursor: pointer;
      height: 28px;
      width: 70px;
      border-radius: 5px;
      background-color: #47cecf;
      color: #fff;
      font-size: 14px;
      font-weight: Regular;
      font-family: FZY1JW--GB1-0;
      border: none;
    }
  }
}
</style>