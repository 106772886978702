<template>
  <div>
    <!-- <bus-nav-mini :OneName="'广告位中心'" :isImg="false"></bus-nav-mini> -->
    <div>
      <div class="tab-bar">
        <div
          :class="{'selected-button':index == buttonIndex}"
          class="banner"
          v-for="(item, index) in tabBar"
          :key="index"
          @click="ClickButton(index, item)"
        >
          <button></button>
          <button>{{item.title}}</button>
          <button></button>
        </div>
      </div>

      <div class="content">
        <bus-banner-list
          :name="name"
          :title="title"
          :count="count"
          :items="items"
          :status="status"
          :itemBar="itemBar"
          :buttonIndex="buttonIndex"
          :listItem="listItem"
          :page="page"
          @Refresh="Refresh"
        ></bus-banner-list>
      </div>
    </div>

    <div class="page">
      <!-- <bus-page :EndPage="EndPage" :url="url"></bus-page> -->
    </div>
  </div>
</template>


<script>
import busBannerOrList from "./AdComponents/busBannerOrList";
import { mapState } from "vuex";
export default {
  name: "Ad",
  components: {
    "bus-banner-list": busBannerOrList
  },
  data() {
    return {
      tabBar: [
        {
          title: "跨境Banner",
          name: "",
          type: "discuss_banner",
          status: "banner",
          tagBar: [],
          url: "/user/exhibitionPic/queryManagerListPage?type="
        },
        {
          title: "找人Banner",
          name: "",
          type: "look_banner",
          status: "banner",
          tagBar: [],
          url: "/user/exhibitionPic/queryManagerListPage?type="
        },
        {
          title: "学习Banner",
          name: "",
          type: "master_title",
          status: "banner",
          tagBar: [],
          url: "/user/exhibitionPic/queryManagerListPage?type="
        },
        {
          title: "跨境主播列表",
          name: "group",
          type: "advertising_position",
          status: "list",
          tagBar: ["昵称", "性别", "讨论组名称", "人数"],
          url:
            this.$store.state.urlName +
            "/message/messageGroup/queryManagerListPage?recommend_type="
        },
        {
          title: "找人用户列表",
          name: "user",
          type: "true",
          status: "list",
          tagBar: ["昵称", "性别", "手机号", "注册时间"],
          url:
            this.$store.state.urlName +
            "/user/userInfo/queryManagerListPage?advertising_position="
        },
        {
          title: "学习助学师列表",
          name: "teacher",
          type: "advertising_position",
          status: "list",
          tagBar: ["昵称", "性别", "手机号", "教学语言"],
          url:
            this.$store.state.urlName +
            "/user/masterInfo/queryManagerListPage?recommend_type="
        }
      ],
      itemBar: [],
      buttonIndex: 0,
      status: "",
      name: "",
      count: 0,
      items: [
        {
          map: {
            userinfo: {}
          }
        }
      ],
      type: "",
      EndPage: 0,
      url: "",
      listItem: {},
      title: "",
      page: 1,
      isPage: false
    };
  },
  computed: {
    ...mapState(["res", "addBanner", "addList", "PageNumber"])
  },
  created() {
    if (this.$route.query.barIndex) {
      this.buttonIndex = this.$route.query.barIndex;
      this.page = this.$route.query.page;
      this.isPage = true;
      // console.log("012")
    }
    this.title = this.tabBar[this.buttonIndex].title;
    this.name = this.tabBar[this.buttonIndex].name;
    this.status = this.tabBar[this.buttonIndex].status;
    this.type = this.tabBar[this.buttonIndex].type;
    this.getBanner(this.tabBar[this.buttonIndex].url);
  },
  methods: {
    ClickButton(index, item) {
      this.listItem = {
        tagBar: item.tagBar,
        url: item.url.split("?")[0],
        name: item.name
      };
      this.buttonIndex = index;
      this.status = item.status;
      this.title = item.title;
      this.name = item.name;
      this.type = item.type;
      this.$store.state.bannerType = this.type;
      this.getBanner(item.url);
      this.CleaningTab(index);
      // this.changeTabIndex(index);
    },
    CleaningTab(index) {
      let a = [
        "序号",
        "封面",
        "昵称",
        "性别",
        "国籍",
        "讨论组名称",
        "类别",
        "使用语言",
        "人数",
        "操作"
      ];
      let b = [
        "序号",
        "头像",
        "昵称",
        "性别",
        "国籍",
        "身份",
        "故乡",
        "偏好",
        "操作"
      ];
      let c = [
        "序号",
        "自我介绍视频",
        "类别",
        "昵称",
        "性别",
        "教学语言",
        "掌握语言",
        "评分",
        "操作"
      ];
      if (index == 3) {
        this.itemBar = a;
      } else if (index == 4) {
        this.itemBar = b;
      } else if (index == 5) {
        this.itemBar = c;
      }
    },
    getBanner(url) {
      let urlPage;
      if (this.isPage) {
        urlPage = url + this.type + "&currentPage=" + this.page;
        this.isPage = false;
      } else {
        urlPage = url + this.type;
      }
      this.$axios.get(urlPage).then(res => {
        if (res.data.code == 0) {
          this.url = url + this.type + "&currentPage=";
          this.Cleaning(res);
        }
      });
    },
    Cleaning(res) {
      this.count = res.data.data.total;
      this.items = res.data.data.rows;
      let page = this.count / 10;
      this.EndPage = Number.isInteger(page) ? page : parseInt(page) + 1;
    },
    Refresh() {
      this.getBanner(this.tabBar[this.buttonIndex].url);
    },
    changeTabIndex(index) {
      this.$router.push({
        name: "Ad",
        query: {
          barIndex: index,
          path: this.$route.name,
          isTab: true,
          page: this.page
        }
      });
    }
  },
  watch: {
    res(newV, oldV) {
      if (!this.addList) {
        this.Cleaning(newV);
      }
    },
    addBanner(newV, oldV) {
      if (!newV) {
        this.Refresh();
      }
    },
    addList(newV, oldV) {
      if (!newV) {
        this.Refresh();
      }
    },
    PageNumber(newV, oldV) {
      this.page = newV;
    }
  }
};
</script>

<style scoped lang="less">
.tab-bar {
  height: 30px;
  min-width:1577px;
  border-bottom: 1px solid #47cecf;
  display: flex;
  flex-wrap: wrap;

  .banner {
    width: 243px;
    height: 30px;
    position: relative;
    background-color: #fff;
    cursor: pointer;

    button {
      padding: 5px;
      height: 30px;
      border: none;
      line-height: 35px;
      font-family: MicrosoftYaHei;
      font-weight: Regular;
      font-size: 20px;
      background-color: #fff;

      &:nth-of-type(1) {
        width: 40px;
        position: absolute;
        left: 0;
        border-radius: 0 0 10px 0;
        background-color: #fff;
      }

      &:nth-of-type(2) {
        position: absolute;
        left: 40px;
        width: 163px;
        top: -10px;
        cursor: pointer;
        border-radius: 10px 10px 0px 0px;
      }

      &:nth-of-type(3) {
        width: 40px;
        position: absolute;
        right: 0;
        border-radius: 0 0 0px 10px;
        background-color: #fff;
      }
    }
  }

  .selected-button {
    background-color: #47cecf;

    button {
      &:nth-of-type(2) {
        background-color: #47cecf;
        color: #fff;
      }
    }
  }
}

.page {
  margin: 100px 0;
  position: relative;
}
</style>